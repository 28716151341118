<template>
    <div v-if="paginator" class="d-flex justify-content-end text-small">
        <div class="d-flex align-items-center" style="gap: 16px">
            <div v-if="paginator.meta.total > 0">
                {{
                    __('pagination.description', {
                        from: paginator.meta.from,
                        to: paginator.meta.to,
                        total: paginator.meta.total,
                    })
                }}
            </div>
            <div v-if="paginator.meta.links.length > 1" class="d-flex">
                <a
                    :class="{
                        disabled: !paginator.links.prev,
                        'text-secondary': !paginator.links.prev,
                    }"
                    :href="paginator.links.prev"
                    class="btn btn-icon border-0"
                    @click.prevent="
                        updateActivePage(paginator.meta.current_page - 1)
                    "
                >
                    <chevron-left />
                </a>
                <a
                    :class="{
                        disabled: !paginator.links.next,
                        'text-secondary': !paginator.links.next,
                    }"
                    :href="paginator.links.next"
                    class="btn btn-icon border-0"
                    @click.prevent="
                        updateActivePage(paginator.meta.current_page + 1)
                    "
                >
                    <chevron-right />
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import ChevronLeft from '@/../icons/ChevronLeft.vue';
import ChevronRight from '@/../icons/ChevronRight.vue';

export default {
    components: { ChevronRight, ChevronLeft },
    props: {
        paginator: Object,
        queryParams: Object,
    },
    methods: {
        updateActivePage(page) {
            if (!page || page > this.paginator.meta.last_page) {
                return;
            }

            this.queryParams.form.page = page;
            this.queryParams.change();
        },
    },
};
</script>
