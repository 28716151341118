<template>
    <collapsable-filter-bar
        :active="queryParams.form.isDirty"
        :is-processing="queryParams.form.processing"
        @clear="queryParams.clear"
    >
        <template #visible-filter>
            <toggle-button
                :model-value="queryParams.form.type"
                :options="[
                    { label: 'Daily', value: 'daily' },
                    { label: 'Weekly', value: 'weekly' },
                ]"
                class="bg-white"
                style="height: 28px"
                @update:modelValue="updateType($event)"
            />
        </template>

        <search-filter
            v-if="queryParams.form.type === 'daily'"
            v-model="queryParams.form.search"
            :is-processing="queryParams.form.processing"
            :show-spinner="false"
            placeholder="Search for Area, Subarea"
            @update:model-value="updateValue('search', $event)"
        />

        <location-filter
            :form="queryParams.form"
            :show-specific-locations="queryParams.form.type === 'daily'"
            @change="updateValue"
        />

        <date-filter
            :attributes="attributes"
            :disabled-dates="disabledDates"
            :model-value="queryParams.form.date"
            :selection-mode="queryParams.form.type"
            @update:modelValue="updateValue('date', $event)"
        />
    </collapsable-filter-bar>
</template>
<script>
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import ToggleButton from '@/Components/ToggleButton.vue';
import { DateTime } from 'luxon';
import DateFilter from '@/Pages/ReportingLight/DateFilter.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import JetButton from '@/Jetstream/Button.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';

export default {
    name: 'reporting-filter-bar',
    components: {
        SearchFilter,
        JetButton,
        CollapsableFilterBar,
        DateFilter,
        JetDatepicker,
        LocationFilter,
        ToggleButton,
    },
    props: {
        queryParams: Object,
    },
    computed: {
        attributes() {
            let attributes = [
                {
                    dot: {
                        style: {
                            'background-color': '#3E56C6',
                        },
                    },
                    popover: {
                        label: 'Report Available',
                        isInteractive: true,
                    },
                    dates: this.availableDates.map((date) => date.end),
                },
            ];

            if (this.queryParams.form.type === 'daily') {
                attributes.push({
                    highlight: { class: 'bg-primary', fillMode: 'solid' },
                    dates: new Date(this.queryParams.form.date),
                });
            } else {
                attributes.push({
                    highlight: {
                        start: { class: 'bg-primary', fillMode: 'solid' },
                        base: {
                            fillMode: 'solid',
                            class: 'bg-blue-light',
                            contentClass: 'color-text',
                        },
                        end: { class: 'bg-primary', fillMode: 'solid' },
                    },
                    dates: {
                        start: new Date(this.$page.props.report.startDate),
                        end: new Date(this.$page.props.report.endDate),
                    },
                });
            }

            return attributes;
        },
        disabledDates() {
            return this.queryParams.form.type === 'daily'
                ? [{ start: DateTime.now().plus({ days: 1 }).toJSDate() }]
                : [{ start: DateTime.now().startOf('week').toJSDate() }];
        },
        availableDates() {
            return this.$page.props.availableReportDates.map((date) => ({
                start: new Date(date.start),
                end: new Date(date.end),
            }));
        },
    },
    methods: {
        updateValue(key, value) {
            if (Object.hasOwn(this.queryParams.form, key)) {
                this.queryParams.form[key] = value;
            }
            this.queryParams.change();
        },
        updateType(type) {
            this.queryParams.form.type = type;

            let today = DateTime.now();

            if (type === 'weekly') {
                this.queryParams.form.date = today
                    .endOf('week')
                    .minus({ week: 1 })
                    .toFormat('yyyy-MM-dd');
            } else {
                this.queryParams.form.date = today.toFormat('yyyy-MM-dd');
            }
            this.queryParams.change();
        },
    },
};
</script>
