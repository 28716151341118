<template>
    <app-layout>
        <page-header :title="pageHeader">
            <p class="m-0">
                {{ __('assets.details.asset_identification.description') }}
            </p>
        </page-header>
        <row>
            <column>
                <jet-form-section type="decent" @submitted="handleSubmit">
                    <template #form>
                        <div>
                            <row>
                                <column>
                                    <jet-input
                                        v-model="form.petitec_identifier"
                                        :description="
                                            __(
                                                'assets.details.petitec_identifier.description',
                                            )
                                        "
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.petitec_identifier
                                        "
                                        :label="
                                            __(
                                                'assets.details.petitec_identifier.label',
                                            )
                                        "
                                        :placeholder="
                                            __(
                                                'assets.details.petitec_identifier.placeholder',
                                            )
                                        "
                                        style="flex-grow: 1"
                                    >
                                        <template #prepend>
                                            <icon
                                                icon="cil-qr-code"
                                                style="
                                                    shape-rendering: crispEdges;
                                                "
                                            />
                                        </template>
                                        <template
                                            v-if="hasCameraSupport"
                                            #appendButton
                                        >
                                            <jet-button
                                                class="border-primary"
                                                type="button"
                                                @click="openPetitecQrCodeModal"
                                            >
                                                {{
                                                    __(
                                                        'assets.details.petitec_identifier.button.scan',
                                                    )
                                                }}
                                            </jet-button>
                                        </template>
                                    </jet-input>
                                </column>
                            </row>
                            <row>
                                <column>
                                    <jet-input
                                        v-model="form.serial_number"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.serial_number
                                        "
                                        :label="
                                            __(
                                                'assets.details.serial_number.label',
                                            )
                                        "
                                        :placeholder="
                                            __(
                                                'assets.details.serial_number.placeholder',
                                            )
                                        "
                                        :required="true"
                                    />
                                </column>
                            </row>

                            <row>
                                <column>
                                    <jet-input
                                        v-model="form.customer_tracking_number"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.customer_tracking_number
                                        "
                                        :label="
                                            __(
                                                'assets.details.customer_tracking_number.label',
                                            )
                                        "
                                        :placeholder="
                                            __(
                                                'assets.details.customer_tracking_number.placeholder',
                                            )
                                        "
                                    />
                                </column>
                            </row>

                            <location-form-fields
                                v-model="form.location_id"
                                :errors="form.errors.location_id"
                            />

                            <row>
                                <column>
                                    <manufacturer-dropdown
                                        v-model="form.manufacturer"
                                        :invalidFeedback="
                                            form.errors.model_id &&
                                            !form.manufacturer
                                                ? __(
                                                      'assets.details.manufacturer.error',
                                                  )
                                                : undefined
                                        "
                                        @update:model-value="
                                            form.model_id = null
                                        "
                                    />
                                </column>
                            </row>
                            <row>
                                <column>
                                    <designation-dropdown
                                        v-model="form.model_id"
                                        :invalidFeedback="form.errors.model_id"
                                        :manufacturer="form.manufacturer"
                                    />
                                </column>
                            </row>
                            <row>
                                <column>
                                    <jet-select
                                        v-model="form.screening_application"
                                        :horizontal="false"
                                        :invalidFeedback="
                                            form.errors.screening_application
                                        "
                                        :label="
                                            __(
                                                'assets.details.screening_application.label',
                                            )
                                        "
                                        :options="screeningApplications"
                                        :placeholder="
                                            __(
                                                'assets.details.screening_application.placeholder',
                                            )
                                        "
                                        :required="true"
                                    />
                                </column>
                            </row>
                            <row>
                                <column>
                                    <jet-select
                                        v-model="form.critical"
                                        :horizontal="false"
                                        :invalidFeedback="form.errors.critical"
                                        :label="
                                            __(
                                                'assets.details.criticality.label',
                                            )
                                        "
                                        :options="criticalities"
                                        :required="true"
                                    />
                                </column>
                            </row>
                        </div>

                        <row>
                            <column>
                                <jet-datepicker
                                    v-model="form.purchased_at"
                                    :horizontal="false"
                                    :invalidFeedback="form.errors.purchased_at"
                                    :label="
                                        __('assets.details.purchased_at.label')
                                    "
                                    :placeholder="
                                        __(
                                            'assets.details.purchased_at.placeholder',
                                        )
                                    "
                                />
                            </column>
                        </row>
                        <row>
                            <column>
                                <jet-datepicker
                                    v-model="form.deployed_at"
                                    :horizontal="false"
                                    :invalidFeedback="form.errors.deployed_at"
                                    :label="
                                        __('assets.details.deployed_at.label')
                                    "
                                    :placeholder="
                                        __(
                                            'assets.details.deployed_at.placeholder',
                                        )
                                    "
                                />
                            </column>
                        </row>
                        <row>
                            <column>
                                <jet-datepicker
                                    v-model="form.warranty_start_date"
                                    :horizontal="false"
                                    :invalidFeedback="
                                        form.errors.warranty_start_date
                                    "
                                    :label="
                                        __(
                                            'assets.details.warranty_start_date.label',
                                        )
                                    "
                                    :placeholder="
                                        __('general.form.select.placeholder')
                                    "
                                />
                            </column>
                        </row>
                        <row>
                            <column>
                                <jet-datepicker
                                    v-model="form.warranty_end_date"
                                    :horizontal="false"
                                    :invalidFeedback="
                                        form.errors.warranty_end_date
                                    "
                                    :label="
                                        __(
                                            'assets.details.warranty_end_date.label',
                                        )
                                    "
                                    :min-date="form.warranty_start_date"
                                    :placeholder="
                                        __('general.form.select.placeholder')
                                    "
                                />
                            </column>
                        </row>
                    </template>

                    <template #actions>
                        <jet-form-buttons
                            :cancel-route="cancelRoute"
                            :disabled="!form.isDirty"
                            :has-changes="form.isDirty"
                            :is-processing="form.processing"
                            :is-update-form="true"
                            @submit="handleSubmit"
                        />
                    </template>
                </jet-form-section>
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import SimpleQrCodeScannerModal from '@/Components/SimpleQrCodeScannerModal.vue';
import DropdownWithAction from '@/Components/DropdownWithAction.vue';
import DesignationDropdown from '@/Pages/Assets/Edit/DesignationDropdown.vue';
import ManufacturerDropdown from '@/Pages/Assets/Edit/ManufacturerDropdown.vue';
import ButtonAddNew from '@/Components/ButtonAddNew.vue';
import { eventBus, events } from '@/eventBus.js';
import { $http } from '@/bootstrap.js';
import { PetiteCIdScannerType } from '@/Components/PetitecIdScanner.vue';
import LocationFormFields from '@/Pages/Assets/Edit/LocationFormFields.vue';
import PageHeader from '@/Components/PageHeader.vue';

export default {
    components: {
        PageHeader,
        LocationFormFields,
        ButtonAddNew,
        ManufacturerDropdown,
        AppLayout,
        DesignationDropdown,
        DropdownWithAction,
        JetButton,
        JetFormButtons,
        JetInput,
        JetFormSection,
        JetSelect,
        JetDatepicker,
        SimpleQrCodeScannerModal,
    },

    data() {
        return {
            models: [],
            isLoadingModels: false,
            form: this.$inertia.form({
                petitec_identifier: null,
                manufacturer: null,
                serial_number: null,
                customer_tracking_number: null,
                owner: null,
                operator: null,
                maintainer: null,
                location_id: null,
                model_id: null,
                screening_application: null,
                purchased_at: null,
                deployed_at: null,
                critical: 'no',
                warranty_start_date: null,
                warranty_end_date: null,
            }),
        };
    },

    computed: {
        cancelRoute() {
            return this.route('assets.show', this.asset.id);
        },
        asset() {
            return this.$page.props.asset;
        },

        types() {
            return this.$page.props.types.data;
        },

        screeningApplications() {
            return this.$page.props.screening_applications.data;
        },

        criticalities() {
            return this.$page.props.criticalities.data;
        },

        hasCameraSupport() {
            return !!navigator.mediaDevices;
        },

        pageHeader() {
            return this.__('assets.details.edit_asset');
        },

        noModelsAvailable() {
            if (this.models && this.models.length === 0) {
                return this.__('designations.not_available');
            }

            return undefined;
        },
        isAllowedToCreateModels() {
            return this.hasAnyPermission('model.create');
        },
        fileUploadUrl() {
            return this.route('attachments.store');
        },
    },

    mounted() {
        this.form = this.$inertia.form({
            petitec_identifier: this.asset.petitec_identifier,
            serial_number: this.asset.serial_number,
            customer_tracking_number: this.asset.customer_tracking_number,
            owner: this.asset.owner ? this.asset.owner.id : null,
            operator: this.asset.operator ? this.asset.operator.id : null,
            manufacturer: this.asset.manufacturer?.id,
            maintainer: this.asset.maintainer ? this.asset.maintainer.id : null,
            location_id: this.asset.location?.id,
            model_id: this.asset.model?.id || null,
            screening_application: this.asset.screening_application?.value,
            purchased_at: this.parseApiDate(this.asset.purchased_at),
            deployed_at: this.parseApiDate(this.asset.deployed_at),
            critical: this.asset.critical ? 'yes' : 'no',
            warranty_start_date: this.parseApiDate(
                this.asset.warranty?.start_date,
            ),
            warranty_end_date: this.parseApiDate(this.asset.warranty?.end_date),
        });

        this.selectedManufacturerId = this.asset.manufacturer.id;
        this.models = this.transformModels(this.$page.props.models.data);
    },

    methods: {
        handleSubmit() {
            this.updateAsset();
        },
        createAsset() {
            this.form
                .transform(this.transformData)
                .post(this.route('assets.store'));
        },
        updateAsset() {
            this.form
                .transform(this.transformData)
                .put(this.route('assets.update', this.asset));
        },
        transformData(data) {
            data = Object.assign({}, data);

            return {
                ...data,
                deployed_at: this.formatAsApiDate(data.deployed_at),
                purchased_at: this.formatAsApiDate(data.purchased_at),
                warranty_start_date: this.formatAsApiDate(
                    data.warranty_start_date,
                ),
                warranty_end_date: this.formatAsApiDate(data.warranty_end_date),
                critical: data.critical === 'yes',
            };
        },
        loadModels({ selectedModelId } = {}) {
            this.models = [];
            this.form.model_id = null;

            if (!this.form.manufacturer) {
                return;
            }

            this.form.processing = true;
            this.isLoadingModels = true;

            $http
                .get(
                    this.route('models.index', {
                        manufacturer: this.form.manufacturer,
                    }),
                )
                .then((resp) => {
                    this.models = this.transformModels(resp.data.data);

                    if (this.models.length === 1) {
                        this.form.model_id = this.models[0].value;
                    } else {
                        this.form.model_id = selectedModelId;
                    }
                })
                .finally(() => {
                    this.form.processing = false;
                    this.isLoadingModels = false;
                });
        },
        handleQrCodeSuccess(code) {
            this.form.petitec_identifier = code.uuid;

            setTimeout(() => {
                this.isQrCodeModalVisible = false;
            }, 300);
        },
        addNewModel() {
            if (!!this.selectedManufacturerId) {
                this.isCreateModelModalVisible = true;
            }
        },
        handleCreateModelSuccess(data) {
            this.loadModels({ selectedModelId: data.id });

            this.isCreateModelModalVisible = false;
        },
        transformModels(models) {
            return models.map((model) => ({
                value: model.id,
                label: model.designation,
                type: model.type,
            }));
        },
        openPetitecQrCodeModal() {
            eventBus.$emit(events.openQrCodeScanner, {
                type: PetiteCIdScannerType.scanId,
                onSuccessfulScan: (code, modal) => {
                    this.form.petitec_identifier = code;
                    modal.close();
                },
            });
        },
    },
};
</script>
