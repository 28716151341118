<template>
    <collapsable-filter-bar
        :active="form.isDirty"
        :is-processing="form.processing"
        @clear="clear"
    >
        <template #visible-filter="{ isOpen }">
            <search-filter
                :is-processing="form.processing"
                :model-value="form.search"
                :placeholder="searchPlaceholder"
                :show-spinner="false"
                @update:modelValue="updateValue('search', $event)"
            />
        </template>

        <div v-if="usages.length > 1">
            <multi-select-dropdown
                :label="__('assets.overview.filter.usage')"
                :model-value="form.usages"
                :options="usages"
                :show-select-all-button="false"
                @update:modelValue="updateValue('usages', $event)"
            />
        </div>
        <div v-if="operabilities.length > 1">
            <multi-select-dropdown
                :label="__('assets.overview.filter.operability')"
                :model-value="form.operabilities"
                :options="operabilities"
                :show-select-all-button="false"
                class="operability-filter"
                @update:modelValue="updateValue('operabilities', $event)"
            >
                <template v-slot:label="{ option }">
                    <div
                        class="d-flex justify-content-between align-items-center"
                        style="gap: 0.5rem"
                    >
                        <span>{{ option.label }}</span
                        ><em
                            :class="option.value"
                            class="operability-indicator"
                        ></em>
                    </div>
                </template>
            </multi-select-dropdown>
        </div>

        <div>
            <multi-select-dropdown
                v-if="types.length > 1"
                :label="__('assets.overview.filter.type')"
                :model-value="form.types"
                :options="types"
                :show-select-all-button="false"
                @update:modelValue="updateValue('types', $event)"
            >
                <template v-slot:label="{ option }">
                    <div
                        class="d-flex justify-content-between align-items-center gap-3"
                    >
                        <span>{{ option.label }}</span
                        ><small class="text-muted">{{ option.value }}</small>
                    </div>
                </template>
            </multi-select-dropdown>
        </div>

        <location-filter :form="form" @change="updateValue" />
    </collapsable-filter-bar>
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';

export default {
    components: {
        LocationFilter,
        SearchFilter,
        CollapsableFilterBar,
        MultiSelectDropdown,
    },
    props: {
        queryParams: Object,
        searchPlaceholder: String,
    },
    emits: ['change'],
    computed: {
        form() {
            return this.queryParams.form;
        },
        types() {
            return this.$page.props.types?.data || [];
        },
        operabilities() {
            return this.$page.props.operabilities?.data || [];
        },
        usages() {
            return this.$page.props.usages?.data || [];
        },
    },
    methods: {
        updateValue(key, value) {
            if (Object.hasOwn(this.form, key)) {
                this.form[key] = value;
            }

            this.queryParams.change();
            this.$emit('change');
        },
        clear() {
            this.queryParams.clear();
            this.$emit('change');
        },
    },
};
</script>
<style scoped>
.operability-indicator {
    width: 0.75rem;
    height: 0.75rem;
    display: inline-block;
    border-radius: 100%;
    border: 1px solid;
}

.operability-indicator.operable {
    border-color: #8e8e93;
}

.operability-indicator.inoperable {
    border-color: #8e8e93;
}

:deep(.operability-filter .c-multi-select-option) {
    padding-right: 0.625rem !important;
}
</style>
