<template>
    <app-layout>
        <asset-details-header />

        <div class="d-flex justify-content-between align-items-top mt-3">
            <activity-filterbar
                :query-params="activeQueryParams"
                class="w-100"
            />
            <teleport :disabled="isDesktop" defer to="#page-header-action">
                <export-menu
                    :active-query-params="activeQueryParams"
                    :non-query-filters="{ assetId: asset.id }"
                    :selection="selection"
                    routeName="activities.export"
                />
            </teleport>
        </div>

        <div class="py-3">
            <activity-log
                :activities="activities"
                :fields="fields"
                :query-params="activeQueryParams"
                context="asset"
            />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import AssetDetailsHeader from '@/Pages/Assets/Details/AssetDetailsHeader.vue';
import ActivityLog from '@/Components/ActivityLog/ActivityLog.vue';
import Pagination from '@/Components/Pagination.vue';
import TestRecordsFilterbar from '@/Pages/TestRecords/TestRecordsFilterbar.vue';
import JetButton from '@/Jetstream/Button.vue';
import ActivityFilterbar from '@/Pages/Activities/ActivityFilterbar.vue';
import useQueryParams from '@/Utils/UseQueryParams';
import { usePage } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';
import ExportMenu from '@/Components/ExportMenu.vue';
import { isDesktop } from '@/Utils/UseMedia';
import useSelectedItems from '@/Utils/UseSelectedItems';

export default {
    components: {
        ExportMenu,
        ActivityFilterbar,
        JetButton,
        TestRecordsFilterbar,
        Pagination,
        AppLayout,
        AssetDetailsHeader,
        ActivityLog,
    },

    setup() {
        const page = usePage();
        const route = useRoute();

        const selection = useSelectedItems(
            'asset-activities',
            page.props.activities.meta.total,
        );

        const activeQueryParams = useQueryParams({
            paramsWithDefaults: {
                search: null,
                start: null,
                end: null,
                sort: { column: 'date', asc: false },
            },
            route: route('assets.activities.index', page.props.asset),
            options: {
                only: ['activities'],
            },
        });

        return {
            activeQueryParams,
            isDesktop,
            selection,
        };
    },

    data() {
        return {
            fields: [
                {
                    key: 'date',
                    label: this.__('activities.overview.cell.date'),
                    _style: 'width: 120px; min-width: 120px; max-width: 120px',
                },
                {
                    key: 'causer',
                    label: this.__('activities.overview.cell.user'),
                    _style: 'width: 200px; min-width: 200px; max-width: 200px',
                },
                {
                    key: 'event',
                    label: this.__('activities.overview.cell.event'),
                    sorter: false,
                },
            ],
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        activities() {
            return this.$page.props.activities;
        },
    },
};
</script>
