<template>
    <app-layout>
        <page-header :title="__('locations.overview.title')">
            <div class="d-flex justify-content-between align-items-start mt-3">
                <collapsable-filter-bar
                    :active="activeQueryParams.form.isDirty"
                    :query-params="activeQueryParams"
                    :show-clear-button="false"
                    class="flex-grow-1"
                >
                    <template #visible-filter="{ isProcessing }">
                        <search-filter
                            :is-processing="activeQueryParams.form.processing"
                            :model-value="activeQueryParams.form.search"
                            :placeholder="
                                __('locations.overview.filter.placeholder')
                            "
                            :show-spinner="true"
                            @update:modelValue="updateValue('search', $event)"
                        />
                    </template>
                </collapsable-filter-bar>
                <teleport :disabled="isDesktop" defer to="#page-header-action">
                    <jet-button
                        v-if="hasAnyPermission('location.create')"
                        @click="$inertia.visit(route('locations.create'))"
                    >
                        {{ __('locations.overview.action.add_airport') }}
                    </jet-button>
                </teleport>
            </div>
        </page-header>
        <data-table
            v-if="locations"
            :actions="actions"
            :columns="visibleFields"
            :items="transformedLocations"
            :no-items-view="noItemsView"
            :query-params="activeQueryParams"
            @row-clicked="editAirport"
        >
            <template #name="{ item }">
                <td class="align-middle">
                    <div>
                        {{ item.name }}
                    </div>
                    <div class="d-flex align-items-center">
                        <current-time :timezone="item.timezone" />
                    </div>
                </td>
            </template>

            <template #country="{ item }">
                <td class="align-middle">
                    <div class="d-flex align-items-center text-center">
                        <icon
                            v-if="item.countryCodeAlpha2"
                            :name="'cif-' + item.countryCodeAlpha2"
                            class="mx-1"
                        />
                        {{ item.country }}
                    </div>
                </td>
            </template>

            <template #usersCount="{ item }">
                <td class="align-middle">
                    <div class="d-flex align-items-center text-center">
                        <div
                            v-c-tooltip="{
                                content: __(
                                    'locations.overview.cell.users.tooltip.total',
                                ),
                            }"
                        >
                            {{ item.totalUsersCount }}
                        </div>
                    </div>
                </td>
            </template>

            <template #assetsCount="{ item }">
                <td class="align-middle">
                    <div class="d-flex align-items-center text-center">
                        {{ item.assetsCount }}
                    </div>
                </td>
            </template>
        </data-table>
        <delete-location-modal />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import CurrentTime from '@/Components/CurrentTime.vue';
import Card from '@/Components/Card.vue';
import DataTable from '@/Components/DataTable.vue';
import PageHeader from '@/Components/PageHeader.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import { eventBus, events } from '@/eventBus.js';
import DeleteLocationModal from '@/Pages/Locations/DeleteLocationModal.vue';
import useQueryParams from '@/Utils/UseQueryParams';
import { isDesktop } from '@/Utils/UseMedia';

const isNumber = (val) => typeof val === 'number';

export default {
    components: {
        DeleteLocationModal,
        CollapsableFilterBar,
        SearchFilter,
        PageHeader,
        DataTable,
        Card,
        CurrentTime,
        AppLayout,
        CustomCardHeader,
        JetButton,
        Pagination,
    },
    setup() {
        const activeQueryParams = useQueryParams({
            paramsWithDefaults: {
                search: null,
                sort: { column: 'icaoCode', asc: true },
            },
            route: route('locations.index'),
            options: {
                only: ['locations'],
            },
        });
        return {
            activeQueryParams,
            isDesktop,
        };
    },

    data() {
        return {
            allFields: [
                {
                    key: 'icaoCode',
                    label: this.__('locations.overview.cell.icao'),
                },
                {
                    key: 'iataCode',
                    label: this.__('locations.overview.cell.iata'),
                },
                {
                    key: 'name',
                    label: this.__('locations.overview.cell.name'),
                },
                {
                    key: 'country',
                    label: this.__('locations.overview.cell.country'),
                },
                {
                    key: 'specificLocationsCount',
                    label: this.__(
                        'locations.overview.cell.specific_locations',
                    ),
                },
                {
                    key: 'usersCount',
                    label: this.__('locations.overview.cell.users'),
                },
                {
                    key: 'assetsCount',
                    label: this.__('locations.overview.cell.assets'),
                },
            ],
            noItemsView: {
                noItems: this.__('locations.overview.results.empty'),
            },
            actions: [
                {
                    permissions: ['location.update'],
                    tooltip: this.__('locations.overview.tooltip.edit_airport'),
                    type: 'primary',
                    click: this.editAirport,
                    icon: 'cil-pencil',
                },
                {
                    permissions: ['location.delete'],
                    tooltip: this.__(
                        'locations.overview.tooltip.delete_airport',
                    ),
                    type: 'danger',
                    click: this.confirmDeleteAirport,
                    icon: 'cil-trash',
                },
            ],
        };
    },

    computed: {
        locations() {
            return this.$page.props.locations;
        },
        visibleFields() {
            return this.allFields;
        },
        transformedLocations() {
            const notAvailable = this.__('general.not_available');

            const { meta, links, data } = this.locations;
            return {
                meta,
                links,
                data: data.map((loc) => ({
                    id: loc.id,
                    icaoCode: loc.icaoCode || notAvailable,
                    iataCode: loc.iataCode || notAvailable,
                    name: loc.name || notAvailable,
                    country: loc.countryCodeAlpha2 || notAvailable,
                    countryCodeAlpha2: loc.countryCodeAlpha2?.toLowerCase(),
                    totalActiveUsersCount: isNumber(loc.totalActiveUsersCount)
                        ? loc.totalActiveUsersCount
                        : notAvailable,
                    totalUsersCount: isNumber(loc.totalUsersCount)
                        ? loc.totalUsersCount
                        : notAvailable,
                    assetsCount: isNumber(loc.assetsCount)
                        ? loc.assetsCount
                        : notAvailable,
                    specificLocationsCount: isNumber(loc.specificLocationsCount)
                        ? loc.specificLocationsCount
                        : notAvailable,
                    timezone: loc.timezone?.identifier,
                })),
            };
        },
    },

    methods: {
        editAirport(airport) {
            this.$inertia.visit(this.route('locations.edit', airport.id));
        },
        confirmDeleteAirport(airport) {
            eventBus.$emit(events.openDeleteLocationModal, {
                locationId: airport.id,
                name: airport.name,
                type: 'airport',
            });
        },
        getActiveUsers(users) {
            return users?.filter((user) => user.status.value === 'active');
        },
        updateValue(key, value) {
            this.activeQueryParams.form[key] = value;
            this.activeQueryParams.change();
        },
    },
};
</script>
