<template>
    <section>
        <slot v-if="$slots.title" name="title" />

        <multi-select-dropdown
            v-if="hasAnyPermission('asset.update')"
            v-model="form.owners"
            :horizontal="horizontal"
            :invalid-feedback="form.errors.owners"
            :is-form-field="true"
            :label="__('stakeholders.edit.field.owners.label')"
            :options="owners"
            :placeholder="__('stakeholders.edit.field.owners.placeholder')"
            :show-searchbar="true"
            :show-select-all-button="false"
            footer-classes="bg-gray-light"
        >
            <template #footer="{ hideElement }">
                <button-add-new
                    v-if="hasAnyPermission('owner.create')"
                    @click.native="addNewOwner(hideElement)"
                />
            </template>
        </multi-select-dropdown>
        <multi-select-dropdown
            v-if="hasAnyPermission('asset.update')"
            v-model="form.operators"
            :horizontal="horizontal"
            :invalid-feedback="form.errors.operators"
            :is-form-field="true"
            :label="__('stakeholders.edit.field.operators.label')"
            :options="operators"
            :placeholder="__('stakeholders.edit.field.operators.placeholder')"
            :show-searchbar="true"
            :show-select-all-button="false"
            footer-classes="bg-gray-light"
        >
            <template #footer="{ hideElement }">
                <button-add-new
                    v-if="hasAnyPermission('operator.create')"
                    @click.native="addNewOperator(hideElement)"
                />
            </template>
        </multi-select-dropdown>
        <multi-select-dropdown
            v-if="hasAnyPermission('asset.update')"
            v-model="form.maintainers"
            :horizontal="horizontal"
            :invalid-feedback="form.errors.maintainers"
            :is-form-field="true"
            :label="__('stakeholders.edit.field.maintainers.label')"
            :options="maintainers"
            :placeholder="__('stakeholders.edit.field.maintainers.placeholder')"
            :show-searchbar="true"
            :show-select-all-button="false"
            footer-classes="bg-gray-light"
        >
            <template #footer="{ hideElement }">
                <button-add-new
                    v-if="hasAnyPermission('maintainer.create')"
                    @click.native="addNewMaintainer(hideElement)"
                />
            </template>
        </multi-select-dropdown>
    </section>
</template>
<script setup>
import ButtonAddNew from '@/Components/ButtonAddNew.vue';
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

defineProps({ form: Object, horizontal: { type: Boolean, default: false } });

const page = usePage();

const owners = computed(() =>
    (page.props.owners?.data ?? []).map((owner) => ({
        label: owner.name,
        value: owner.id,
    })),
);
const operators = computed(() =>
    (page.props.operators?.data ?? []).map((operator) => ({
        label: operator.name,
        value: operator.id,
    })),
);
const maintainers = computed(() =>
    (page.props.maintainers?.data ?? []).map((maintainer) => ({
        label: maintainer.name,
        value: maintainer.id,
    })),
);
</script>
