<template>
    <div>
        <card>
            <template #header>
                <h5 class="mb-0">{{ __('owners.overview.title') }}</h5>
                <jet-button
                    v-if="hasAnyPermission('owner.create')"
                    color="primary"
                    @click.stop="createOwner"
                    >{{ __('owners.overview.action.add') }}
                </jet-button>
            </template>
            <data-table
                :actions="actions"
                :columns="fields"
                :items="owners"
                :no-items-view="noItemsView"
                :query-params="activeQueryParams"
            >
                <template #contact="{ item }">
                    <td class="align-middle">
                        <div v-if="item.email || item.description">
                            <div v-if="item.email">
                                <email-link :email="item.email" />
                            </div>
                            <div v-if="item.description">
                                {{ item.description }}
                            </div>
                        </div>
                        <div v-else>
                            {{ __('general.not_available') }}
                        </div>
                    </td>
                </template>
                <template #assets_count="{ item }">
                    <td class="align-middle">
                        <div>{{ item.assetsCount || 0 }}</div>
                    </td>
                </template>
                <template #locations_count="{ item }">
                    <td class="align-middle">
                        <div>{{ item.topLevelLocationsCount || 0 }}</div>
                    </td>
                </template>
            </data-table>
        </card>
        <update-or-create-owner-form-modal />
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import TotalTableItems from '@/Components/TotalTableItems.vue';
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import EmailLink from '@/Components/EmailLink.vue';
import UpdateOrCreateOwnerFormModal from '@/Pages/Stakeholders/Owners/UpdateOrCreateOwnerFormModal.vue';
import { eventBus, events } from '@/eventBus.js';
import DeleteStakeholderModal from '@/Pages/Stakeholders/DeleteStakeholderModal.vue';
import DataTable from '@/Components/DataTable.vue';
import Card from '@/Components/Card.vue';
import useQueryParams from '@/Utils/UseQueryParams';

export default {
    name: 'OwnersOverview',
    components: {
        Card,
        DataTable,
        DeleteStakeholderModal,
        UpdateOrCreateOwnerFormModal,
        EmailLink,
        CustomCardHeader,
        TotalTableItems,
        Pagination,
        JetButton,
    },
    setup() {
        const activeQueryParams = useQueryParams({
            paramsWithDefaults: {
                sort: { column: 'name', asc: true },
            },
            route: route('stakeholders.index'),
            options: {
                only: ['owners'],
                sortKey: 'owners_sort',
            },
        });
        return {
            activeQueryParams,
        };
    },
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: this.__('owners.overview.cell.name'),
                },
                {
                    key: 'contact',
                    label: this.__('owners.overview.cell.contact'),
                    _style: 'white-space: nowrap;width:300px;min-width:300px',
                    sorter: false,
                },
                {
                    key: 'assets_count',
                    label: this.__('owners.overview.cell.assets'),
                    _style: 'white-space: nowrap;width:90px;',
                    sorter: false,
                },
                {
                    key: 'locations_count',
                    label: this.__('owners.overview.cell.locations'),
                    _style: 'white-space: nowrap;width:90px;',
                    sorter: false,
                },
            ],
            noItemsView: {
                noItems: this.__('owners.overview.results.empty'),
            },
            actions: [
                {
                    permissions: ['owner.update'],
                    tooltip: this.__('general.action.edit'),
                    type: 'primary',
                    click: this.editOwner,
                    icon: 'cil-pencil',
                },
                {
                    permissions: ['owner.delete'],
                    tooltip: this.__('general.action.delete'),
                    type: 'danger',
                    click: this.deleteOwner,
                    icon: 'cil-trash',
                },
            ],
        };
    },
    computed: {
        owners() {
            return this.$page.props.owners;
        },
    },
    methods: {
        createOwner() {
            eventBus.$emit(events.openCreateOwnerModal);
        },
        editOwner(item) {
            eventBus.$emit(events.openEditOwnerModal, { owner: item });
        },
        deleteOwner(item) {
            eventBus.$emit(events.openConfirmDeleteStakeholderModal, {
                stakeholder: item,
                type: 'owner',
            });
        },
    },
};
</script>
