<template>
    <app-layout>
        <page-header :title="__('assets.overview.headline')">
            <div class="d-flex justify-content-between align-items-start mt-3">
                <filter-bar
                    :query-params="activeQueryParams"
                    :search-placeholder="
                        __('assets.overview.filter.placeholder')
                    "
                    @change="selection.resetSelection"
                />
                <teleport :disabled="isDesktop" defer to="#page-header-action">
                    <jet-button
                        v-if="hasAnyPermission('asset.create')"
                        @click.stop="$inertia.visit(route('assets.create'))"
                    >
                        {{ __('assets.details.create_new_asset') }}
                    </jet-button>
                </teleport>
            </div>
        </page-header>

        <single-column-table-view
            v-if="isMobile"
            :fields="fields"
            :items="transformedAssets"
            :query-params="activeQueryParams"
            @row-clicked="showDetails"
        />
        <div v-else>
            <data-table
                :columns="fields"
                :items="transformedAssets"
                :no-items-view="noItemsView"
                :query-params="activeQueryParams"
                :selection-enabled="
                    hasFeature('asset-batch-operations') &&
                    $page.props.bulkActions.length > 0
                        ? 'global-assets'
                        : false
                "
                @row-clicked="showDetails"
            >
                <template #manufacturer="{ item }">
                    <td>
                        {{ item.manufacturer }}
                    </td>
                </template>

                <template #type="{ item }">
                    <td>
                        <div>{{ item.type }}</div>
                    </td>
                </template>

                <template #designation="{ item }">
                    <td>
                        <div>
                            {{ item.designation }}
                        </div>
                        <small class="text-nowrap text-dark"
                            >{{ __('assets.overview.cell.serial_number') }}:
                            {{ item.serialNumber }}</small
                        >
                    </td>
                </template>

                <template #location="{ item }">
                    <td>
                        {{ item.location.iataCode }}
                    </td>
                </template>
                <template #specific_location="{ item }">
                    <specific-location-overview-cell
                        :location="item.location"
                    />
                </template>
                <template #area="{ item }">
                    <area-location-overview-cell :location="item.location" />
                </template>
                <template #status="{ item }">
                    <td>
                        <div>
                            <div
                                class="d-flex justify-content-between align-items-top"
                            >
                                <status-badge
                                    :operability="item.status.operability"
                                    :usage="item.status.usageCategory"
                                />
                                <Link
                                    v-if="item.status.workOrdersInProgress > 0"
                                    :href="
                                        route(
                                            'assets.workorders.index',
                                            item.id,
                                        )
                                    "
                                    @click.stop=""
                                >
                                    <work-order-badge
                                        v-c-tooltip="{
                                            content: __(
                                                'assets.overview.tooltip.work_orders_in_progress',
                                                {
                                                    '%d': item.status
                                                        .workOrdersInProgress,
                                                },
                                            ),
                                        }"
                                        :count="
                                            item.status.workOrdersInProgress
                                        "
                                    />
                                </Link>
                            </div>
                            <small
                                v-if="
                                    item.status.hasOwnProperty(
                                        'operabilityLastCheckedAt',
                                    ) &&
                                    hasAnyPermission('asset.status_check.read')
                                "
                                class="text-nowrap text-muted"
                            >
                                <status-check-date :status="item.status" />
                            </small>
                        </div>
                    </td>
                </template>

                <template #issues="{ item }">
                    <td>
                        <div class="align-middle">
                            <div v-for="issue in item.issues">
                                <issue-badge :issue="issue" />
                            </div>
                            <div v-if="item.scores">
                                <div
                                    v-for="(score, label) in item.scores.data"
                                    :key="label"
                                    class="d-flex text-muted text-sm text-uppercase justify-content-between"
                                >
                                    <div class="mr-2">
                                        <small>{{ label }}:</small>
                                    </div>
                                    <div>
                                        <small>{{ score }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </template>
            </data-table>
        </div>
        <batch-jobs
            v-if="hasFeature('asset-batch-operations')"
            :query-params="activeQueryParams.urlParams()"
            :selection="selection"
        />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import FilterBar from '@/Components/FilterBar.vue';
import IssueBadge from '@/Components/IssueBadge.vue';
import StatusBadge from '@/Components/StatusBadge.vue';
import StatusCheckDate from '@/Components/StatusCheckDate.vue';
import BatchJobs from '@/Pages/Assets/BatchJobs/BatchJobs.vue';
import OnboardedAssetIssue from '@/Pages/Assets/OnboardedAssetIssue.vue';
import WorkOrderBadge from '@/Components/WorkorderBadge.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import DataTable from '@/Components/DataTable.vue';
import { Link, usePage } from '@inertiajs/vue3';
import PageHeader from '@/Components/PageHeader.vue';
import SingleColumnTableView from '@/Pages/SingleColumnTableView.vue';
import useQueryParams from '@/Utils/UseQueryParams';
import useSelectedItems from '@/Utils/UseSelectedItems';
import { isDesktop, isMobile } from '@/Utils/UseMedia';
import SpecificLocationOverviewCell from '@/Components/SpecificLocationOverviewCell.vue';
import AreaLocationOverviewCell from '@/Components/AreaLocationOverviewCell.vue';
import { when } from '@/Utils/when';

export default {
    components: {
        AreaLocationOverviewCell,
        SpecificLocationOverviewCell,
        SingleColumnTableView,
        PageHeader,
        DataTable,
        JetCheckbox,
        OnboardedAssetIssue,
        WorkOrderBadge,
        BatchJobs,
        AppLayout,
        JetButton,
        Pagination,
        IssueBadge,
        FilterBar,
        StatusBadge,
        StatusCheckDate,
        Link,
    },

    setup() {
        const page = usePage();
        const activeQueryParams = useQueryParams({
            paramsWithDefaults: {
                search: null,
                usages: [],
                operabilities: [],
                types: [],
                locations: [],
                specificLocations: [],
                sort: { column: 'status', asc: false },
            },
            route: route('assets.index'),
            options: {
                only: ['assets'],
            },
        });

        const selection = useSelectedItems(
            'global-assets',
            page.props.assets.meta.total,
        );

        return {
            activeQueryParams,
            isMobile,
            isDesktop,
            selection,
        };
    },

    data() {
        return {
            noItemsView: {
                noItems: this.__('assets.overview.results.empty'),
            },
            isSearching: false,
            selectedAsset: null,
        };
    },

    computed: {
        fields() {
            return [
                {
                    key: 'status',
                    label: this.__('assets.overview.cell.status'),
                    _style: 'width: 15%',
                },
                {
                    key: 'type',
                    label: this.__('assets.overview.cell.type'),
                    _style: 'width: 90px',
                },
                {
                    key: 'designation',
                    label: this.__('assets.overview.cell.designation'),
                    _style: 'width: 20%',
                },
                when(this.$page.props.location?.data?.length > 1, {
                    key: 'location',
                    label: this.__('assets.overview.cell.airport'),
                    _style: 'width: 90px',
                }),
                {
                    key: 'specific_location',
                    label: this.__('assets.overview.cell.location'),
                    _style: 'width: 15%; white-space: nowrap;',
                },
                {
                    key: 'area',
                    label: this.__('locations.area.label'),
                    _style: 'width: 15%; white-space: nowrap;',
                },
                {
                    key: 'manufacturer',
                    label: this.__('assets.overview.cell.manufacturer'),
                },
                {
                    key: 'issues',
                    label: this.__('assets.overview.cell.issues'),
                    _style: 'width: 15%',
                },
            ].filter(Boolean);
        },
        assets() {
            return this.$page.props.assets;
        },
        transformedAssets() {
            const notAvailable = this.__('general.not_available');

            const { meta, links, data } = this.assets;

            return {
                meta,
                links,
                data: data.map((asset) => ({
                    id: asset.id,
                    type: asset.type.value || notAvailable,
                    designation: asset.designation
                        ? asset.designation.label
                        : notAvailable,
                    manufacturer: asset.manufacturer
                        ? asset.manufacturer.name
                        : notAvailable,
                    petitec_identifier: asset.petitec_identifier,
                    serialNumber: asset.serialNumber || notAvailable,
                    location: asset.location,
                    issues: asset.issues,
                    status: asset.status,
                    scores: asset.scores,
                })),
            };
        },
    },

    methods: {
        getLocationName(location) {
            if (!location) {
                return this.__('general.not_available');
            }

            return [location.name]
                .reduce(
                    (unique, item) =>
                        unique.includes(item) ? unique : [...unique, item],
                    [],
                )
                .filter((item) => item)
                .join(' - ');
        },
        showDetails(asset) {
            this.$inertia.visit(this.route('assets.show', asset.id));
        },
    },
};
</script>
