export const transformSortToQuery = (sort: {
    column: string;
    asc: boolean;
}) => {
    if (!sort) {
        return undefined;
    }

    let sortDirection = sort.asc ? 'asc' : 'desc';
    return `${sort.column}.${sortDirection}`;
};

export const transformQueryToSort = (sort: string, defaultSorting) => {
    if (!sort) {
        return defaultSorting;
    }

    let [column, direction] = sort.split('.');
    return {
        column,
        asc: direction === 'asc',
    };
};
