<template>
    <dropdown
        v-c-tooltip="{ content: __('navigation.profile'), placement: 'bottom' }"
        :caret="false"
        variant="nav-item"
    >
        <template #toggler>
            <div
                class="rounded-circle bg-white p-0 flex-shrink-0 d-flex align-items-center justify-content-center"
                style="width: 2rem; height: 2rem"
            >
                <span class="text-primary" style="font-weight: 900">{{
                    userInitials
                }}</span>
            </div>
        </template>
        <dropdown-item
            :href="$route('profile.show')"
            @click="router.visit($route('profile.show'))"
        >
            {{ __('navigation.profile') }}
        </dropdown-item>
        <dropdown-divider />
        <dropdown-item
            class="d-flex align-items-center justify-content-between"
            @click="logout"
        >
            {{ __('navigation.logout') }}
            <logout-icon />
        </dropdown-item>
    </dropdown>
</template>

<script setup>
import Dropdown from '@/Components/Dropdown.vue';
import DropdownItem from '@/Components/DropdownItem.vue';
import { router, usePage } from '@inertiajs/vue3';
import DropdownDivider from '@/Components/DropdownDivider.vue';
import LogoutIcon from '../../icons/LogoutIcon.vue';
import { computed } from 'vue';

const logout = () => router.post(route('logout'));

const userInitials = computed(() => {
    const user = usePage().props.auth.user;
    return (user.firstName[0] + user.lastName[0]).toUpperCase();
});
</script>
