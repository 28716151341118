<template>
    <sidebar>
        <sidebar-header class="p-0">
            <div
                class="sidebar-brand-wrapper d-flex justify-content-center align-items-center pt-5 pb-4 w-100"
            >
                <sidebar-brand
                    class="d-flex justify-content-center align-items-center clickable"
                    @click.stop="$inertia.visit('/')"
                >
                    <img
                        v-if="logoUrl"
                        :src="logoUrl"
                        alt="App logo"
                        class="sidebar-brand-full"
                    />
                    <img
                        v-if="logoUrl"
                        :src="logoUrl"
                        alt="Small App logo"
                        class="sidebar-brand-narrow"
                        style="width: 42px; height: 42px"
                    />
                    <icon
                        v-else
                        icon="cil-image-plus"
                        style="width: 25px; height: 25px"
                    />
                </sidebar-brand>
            </div>
            <sidebar-toggler />
        </sidebar-header>
        <div class="d-flex flex-column justify-content-between flex-grow-1">
            <sidebar-nav>
                <sidebar-nav-item
                    v-if="
                        hasAnyPermission('asset.update') &&
                        $page.props.assets_stats.onboardingIncomplete > 0
                    "
                    :count="$page.props.assets_stats.onboardingIncomplete"
                    icon="cil-tags"
                    routeName="assets.activation"
                />
                <sidebar-nav-item
                    v-if="
                        hasFeature('asset-work-order-management') &&
                        hasAnyPermission('workorder.read')
                    "
                    icon="cil-view-column"
                    routeName="workorders.index"
                />
                <sidebar-nav-item
                    v-if="
                        hasFeature('dashboard') &&
                        hasAnyPermission('dashboard.read')
                    "
                    icon="cil-bar-chart"
                    routeName="dashboard.index"
                />
                <sidebar-nav-item
                    v-if="hasAnyPermission('asset.read')"
                    :count="totalAssetsCount"
                    icon="cil-layers"
                    routeName="assets.index"
                >
                    <ul
                        :class="{
                            'd-none':
                                !$page.props.activeNavigation['assets.index'],
                        }"
                        class="list-unstyled sidebar-subnav"
                    >
                        <sidebar-sub-nav-item
                            :active="routeContainsUsageCategory('active')"
                            :href="
                                route('assets.index', {
                                    usages: ['active'],
                                })
                            "
                            class="d-block"
                        >
                            {{ __('assets.sidebar.active') }} ({{
                                countAssetsByUsageCategory('active')
                            }})
                        </sidebar-sub-nav-item>
                        <sidebar-sub-nav-item
                            :active="routeContainsUsageCategory('spare')"
                            :href="
                                route('assets.index', {
                                    usages: ['spare'],
                                })
                            "
                            class="d-block"
                        >
                            {{ __('assets.sidebar.spare') }} ({{
                                countAssetsByUsageCategory('spare')
                            }})
                        </sidebar-sub-nav-item>
                        <sidebar-sub-nav-item
                            :active="
                                routeContainsUsageCategory(['decommissioned'])
                            "
                            :href="
                                route('assets.index', {
                                    usages: ['decommissioned'],
                                })
                            "
                            class="d-block"
                        >
                            {{ __('assets.sidebar.decommissioned') }} ({{
                                countAssetsByUsageCategory('decommissioned')
                            }})
                        </sidebar-sub-nav-item>
                    </ul>
                </sidebar-nav-item>

                <li v-if="showReporting" class="nav-item">
                    <a
                        :class="{
                            'c-active': isReportingActive,
                        }"
                        class="nav-link"
                        href="javascript:void(0)"
                        @click.prevent="
                            showReportingSubNav = !showReportingSubNav
                        "
                    >
                        <icon class="nav-icon" name="cil-description" />
                        {{ __('navigation.reporting') }}
                    </a>
                    <collapse :show="isReportingActive || showReportingSubNav">
                        <ul class="list-unstyled sidebar-subnav">
                            <sidebar-sub-nav-item
                                v-if="showInoperableAssets"
                                :active="
                                    route().current() ===
                                    'reporting-light.index'
                                "
                                :href="route('reporting-light.index')"
                                class="d-block"
                            >
                                {{
                                    __('navigation.reporting.inoperable_assets')
                                }}
                            </sidebar-sub-nav-item>
                            <sidebar-sub-nav-item
                                v-if="showTestRecords"
                                :active="route().current() === 'tests.index'"
                                :href="route('tests.index')"
                            >
                                {{ __('navigation.reporting.tests') }}
                            </sidebar-sub-nav-item>
                            <sidebar-sub-nav-item
                                v-if="hasAnyPermission('activity.read')"
                                :active="
                                    route().current() === 'activities.index'
                                "
                                :href="route('activities.index')"
                            >
                                {{ __('navigation.reporting.activities') }}
                            </sidebar-sub-nav-item>
                        </ul>
                    </collapse>
                </li>
                <sidebar-nav-title
                    v-if="
                        hasAnyPermission([
                            'user.index',
                            'location.index',
                            'settings.index',
                        ])
                    "
                    >{{ __('navigation.settings') }}
                </sidebar-nav-title>
                <sidebar-nav-item
                    v-if="hasAnyPermission('user.index')"
                    icon="cil-group"
                    routeName="users.index"
                />
                <sidebar-nav-item
                    v-if="hasAnyPermission('location.index')"
                    icon="cil-airplane-mode"
                    routeName="locations.index"
                />
                <sidebar-nav-item
                    v-if="
                        hasAnyPermission([
                            'owner.index',
                            'operator.index',
                            'maintainer.index',
                        ])
                    "
                    icon="cil-sitemap"
                    routeName="stakeholders.index"
                />
                <sidebar-nav-item
                    v-if="hasAnyPermission('settings.index')"
                    icon="cil-cog"
                    routeName="settings.index"
                />
                <li class="nav-item" style="margin-top: 2rem">
                    <a
                        :href="supportLink"
                        :title="__('navigation.support')"
                        class="nav-link d-flex justify-content-between align-items-center"
                        style="font-weight: 900"
                    >
                        <span
                            class="d-flex nav-icon hidden-wide align-items-center"
                            style="line-height: 1.25rem"
                            >?</span
                        >
                        <span
                            class="d-flex justify-content-between align-items-center w-100"
                            >{{ __('navigation.support') }}
                            <icon name="cil-external-link"
                        /></span>
                    </a>
                </li>
            </sidebar-nav>
            <div
                class="d-flex flex-column align-items-center pt-3 hidden-narrow"
            >
                <optional-link :href="curieTechUrl">
                    <icon
                        class="logo-curie"
                        name="logoCurie"
                        style="height: 22px; width: auto"
                        title="Curie Technologies"
                    />
                </optional-link>
                <div v-if="version" class="px-4 py-2 version text-center">
                    <small :title="commit" class="text-muted"
                        ><b>v{{ version }}</b></small
                    >
                </div>
            </div>
        </div>
    </sidebar>
    <div
        v-if="sidebar.visible"
        :class="{ show: sidebar.visible }"
        class="fade sidebar-backdrop"
        @click="sidebar.close"
    />
</template>

<script setup>
import SidebarSubNavItem from '@/Components/SidebarSubNavItem.vue';
import OptionalLink from '@/Components/OptionalLink.vue';
import Sidebar from '@/Components/Sidebar.vue';
import SidebarNavItem from '@/Components/SidebarNavItem.vue';
import SidebarNav from '@/Components/SidebarNav.vue';
import SidebarNavTitle from '@/Components/SidebarNavTitle.vue';
import SidebarBrand from '@/Components/SidebarBrand.vue';
import SidebarToggler from '@/Components/SidebarToggler.vue';
import SidebarHeader from '@/Components/SidebarHeader.vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useSidebarStore } from '@/stores/sidebar.js';
import { router, usePage } from '@inertiajs/vue3';
import { isEqual } from 'lodash';
import Collapse from '@/Components/Collapse.vue';
import { hasAnyPermission } from '@/mixins/hasAnyPermission.js';
import { hasFeature } from '@/mixins/hasFeature.js';

const sidebar = useSidebarStore();
const showReportingSubNav = ref(false);
const showReporting = computed(
    () => showInoperableAssets || showActivities || showTestRecords,
);
const isReportingActive = computed(() => {
    return (
        route().current().startsWith('reporting-light.') ||
        route().current().startsWith('activities.') ||
        route().current().startsWith('tests.')
    );
});
const showInoperableAssets = computed(
    () =>
        hasFeature('reporting-light') &&
        hasAnyPermission('reporting_light.read'),
);

const showActivities = computed(() => hasAnyPermission('activities.read'));
const showTestRecords = computed(
    () => hasAnyPermission('test.overview') && hasFeature('test_record'),
);

const $page = usePage();
const supportLink = computed(() => $page.props.supportLink);
const userManual = computed(() => `user_manual_${$page.props.locale}.pdf`);
const logoUrl = computed(() => $page.props.settings.big_logo_url);
const version = computed(() => $page.props.version?.semver);
const commit = computed(() => $page.props.version?.commit_hash);
const totalAssetsCount = computed(() =>
    Object.keys($page.props.assets_stats)
        .filter((key) => key !== 'onboardingIncomplete')
        .reduce((a, b) => a + $page.props.assets_stats[b], 0),
);
const curieTechUrl = computed(() => $page.props.curie_tech_url);

// methods: {
function countAssetsByUsageCategory(usageCategory) {
    return $page.props.assets_stats[usageCategory] || 0;
}

function routeContainsUsageCategory(usageCategory) {
    if (route().current() !== 'assets.index') {
        return false;
    }

    const params = route().params;

    // Wrap in array if necessary
    usageCategory = Array.isArray(usageCategory)
        ? usageCategory
        : [usageCategory];

    return isEqual(params.usages, usageCategory);
}

function closeSidebarOnSmallScreens() {
    if (document.body.clientWidth < 992) {
        sidebar.close();
    }
}

let removeEventListener;

onMounted(() => {
    removeEventListener = router.on('start', closeSidebarOnSmallScreens);
});

onUnmounted(() => {
    removeEventListener && removeEventListener();
});
</script>
