<template>
    <div
        :key="id"
        class="d-flex align-items-center justify-content-between border-top p-3 clickable"
        role="menuitem"
        @click.prevent="exportItems"
    >
        <span class="d-flex align-items-center" style="gap: 5px">
            <slot />
        </span>
        <a
            :href="props.route"
            class="position-relative d-flex align-items-center justify-content-center"
        >
            <spinner v-if="isProcessing" class="position-absolute" />
            <span
                :style="{ visibility: isProcessing ? 'hidden' : 'visible' }"
                >{{ __('general.action.generate') }}</span
            >
        </a>
    </div>
</template>
<script setup>
import { ref, useId } from 'vue';
import { route } from 'ziggy-js';
import { $http } from '@/bootstrap.js';
import { downloadFile, getResponseMeta } from '@/Utils/downloadFile';
import { router } from '@inertiajs/vue3';

const id = useId();

const props = defineProps({
    type: String,
    route: String,
    filters: Object,
    nonQueryFilters: Object, // additional filters that are not part of the URL query params eg. asset id
    selection: Object,
    download: {
        type: Boolean,
        default: false,
    },
});

const emits = defineEmits(['done', 'error']);

const isProcessing = ref(false);

const exportItems = () => {
    if (isProcessing.value) {
        return;
    }

    isProcessing.value = true;

    let filters = {
        ...props.filters,
        ...props.nonQueryFilters(),
    };

    if (props.download) {
        $http
            .get(
                route(props.route, {
                    type: props.type,
                    filters,
                    selection: props.selection,
                }),
            )
            .then((resp) => {
                const { fileName, mimeType } = getResponseMeta(resp);
                downloadFile(resp.data, fileName, mimeType);
                emits('done');
            })
            .catch((error) => {
                emits('error', { id, error: error.response.data || error });
            })
            .finally(() => {
                isProcessing.value = false;
            });
    } else {
        router.get(
            route(props.route, {
                type: props.type,
                filters,
                selection: props.selection,
            }),
            {
                preserveScroll: true,
                preserveState: true,
                onSuccess: () => {
                    emits('done');
                },
                onError: (error) => {
                    emits('error', { id, error: error.response.data || error });
                },
                onFinish: () => {
                    isProcessing.value = false;
                },
            },
        );
    }
};
</script>
