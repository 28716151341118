import { useStorage } from '@vueuse/core';
import { computed, onUnmounted } from 'vue';
import { debounce } from 'lodash';
import { router } from '@inertiajs/vue3';

export default function useSelectedItems(name: string, totalItems: number) {
    if (!name) {
        return {};
    }

    const selectedItems = useStorage(
        name,
        {
            all: false,
            include: new Set([]),
            exclude: new Set([]),
        },
        sessionStorage,
        {
            // Custom serializer to use arrays instead of sets
            serializer: {
                read: (value) => {
                    let json = JSON.parse(value);
                    return {
                        all: json.all,
                        include: new Set(json.include),
                        exclude: new Set(json.exclude),
                    };
                },
                write: (value) => {
                    return JSON.stringify({
                        all: value.all,
                        include: Array.from(value.include),
                        exclude: Array.from(value.exclude),
                    });
                },
            },
        },
    );

    const areAllItemsSelected = computed(() => {
        return (
            selectedItems.value.all && selectedItems.value.exclude.size === 0
        );
    });

    // The number of selected items
    const selectedItemsCount = computed(() => {
        const { all, include, exclude } = selectedItems.value;

        if (all) {
            return totalItems - exclude.size;
        } else {
            return include.size;
        }
    });

    // This is used for the "Select all" checkbox to have
    // an indeterminate state when only some items are selected
    const isIndeterminate = computed(() => {
        const { all, include, exclude } = selectedItems.value;

        // If all items are selected but there are exclusions, it is indeterminate
        if (all && exclude.size > 0) {
            return true;
        }

        // If not all items are selected but there are included items, it is indeterminate
        if (!all && include.size > 0) {
            return true;
        }

        // Otherwise, it is not indeterminate
        return false;
    });

    // Checks if an item is selected
    const isSelectedItem = function (id) {
        const { all, include, exclude } = selectedItems.value;

        if (all) {
            // If all items are selected, the item is selected unless it is explicitly in the `exclude` set
            return !exclude.has(id);
        } else {
            // If not all items are selected, the item is selected if it is explicitly in the `include` set
            return include.has(id);
        }
    };

    // Toggles the selection of an item
    function toggleItemSelection(id) {
        const { all, include, exclude } = selectedItems.value; // Access current selection state

        if (all) {
            // If all are currently selected
            if (exclude.has(id)) {
                // If the item is in the `exclude` set, remove it (making it selected)
                exclude.delete(id);
            } else {
                // If the item is not in the `exclude` set, add it (making it unselected)
                exclude.add(id);
            }
            // Update the state with the modified `exclude` set
            selectedItems.value = { all: true, include: new Set(), exclude };
        } else {
            // If not all are selected
            if (include.has(id)) {
                // If the item is in the `include` set, remove it (making it unselected)
                include.delete(id);
            } else {
                // If the item is not in the `include` set, add it (making it selected)
                include.add(id);
            }

            // Adjust state for "none selected" scenario
            if (include.size === 0 && exclude.size === 0) {
                // No items explicitly included or excluded => none selected
                selectedItems.value = {
                    all: false,
                    include: new Set(),
                    exclude: new Set(),
                };
            } else {
                // Update the state with the modified `include` set
                selectedItems.value = {
                    all: false,
                    include,
                    exclude: new Set(),
                };
            }
        }
    }

    // Toggles all selected items on or off
    const toggleAllSelection = () => {
        const { all } = selectedItems.value;

        debounce(() => {
            // Select all in case some are selected
            selectedItems.value = {
                all: !!isIndeterminate.value ? true : !all,
                include: new Set(),
                exclude: new Set(),
            };
        }, 1)();
    };

    // Resets the current selection
    const resetSelection = () => {
        selectedItems.value = {
            all: false,
            include: new Set(),
            exclude: new Set(),
        };
    };

    const getSelectionData = () => {
        return {
            all: areAllItemsSelected.value,
            include: Array.from(selectedItems.value.include),
            exclude: Array.from(selectedItems.value.exclude),
        };
    };

    // Listen for navigation events and reset the selection
    // once the user navigates to a different page
    const removeListener = router.on('start', (event) => {
        if (document.location.pathname !== event.detail.visit.url.pathname) {
            resetSelection();
        }

        return true;
    });

    onUnmounted(() => {
        removeListener();
    });

    return {
        selectedItems,
        selectedItemsCount,
        isSelectedItem,
        isIndeterminate,
        areAllItemsSelected,
        toggleAllSelection,
        toggleItemSelection,
        resetSelection,
        getSelectionData,
    };
}
