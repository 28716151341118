<template>
    <dropdown
        v-c-tooltip="{
            content: __('navigation.notifications'),
            placement: 'bottom',
        }"
        :auto-close="'outside'"
        :caret="false"
        class="notification-center"
        variant="nav-item"
    >
        <template #toggler>
            <icon icon="cil-bell" style="width: 22px; height: 20px" />
            <badge
                v-if="notifications.length"
                class="notification-count"
                v-text="counter"
            />
        </template>
        <dropdown-header
            class="d-flex justify-content-between align-items-center"
            style="flex-wrap: wrap"
        >
            <strong>{{
                __('notification_center.summary', {
                    count: notifications.length,
                })
            }}</strong
            >&nbsp;
            <a
                v-if="notifications.length && !isLoading"
                :href="$route('notifications.mark_as_read')"
                @click.stop.prevent="markAllAsRead"
                >{{ __('notification_center.action.mark_all_as_read') }}</a
            >
            <spinner v-if="isLoading" />
        </dropdown-header>
        <div v-if="notifications.length" class="notification-item-container">
            <template v-for="notification in notifications">
                <notification
                    :notification="notification"
                    class="notification-item"
                    @read="removeReadNotification"
                />
            </template>
        </div>
    </dropdown>
</template>
<script>
import { eventBus, events } from '@/eventBus';
import { setupEcho, subscribe } from '@/echo';
import Notification from '@/Components/NotificationCenter/Notification.vue';
import { throttle } from 'lodash';
import { $http } from '@/bootstrap.js';
import Dropdown from '@/Components/Dropdown.vue';

import Badge from '@/Components/RoundedBadge.vue';
import { CDropdownHeader } from '@coreui/vue/dist/esm/components/dropdown/index.js';
import DropdownHeader from '@/Components/DropdownHeader.vue';

export default {
    components: {
        DropdownHeader,
        CDropdownHeader,
        Badge,

        Dropdown,
        Notification,
    },
    data() {
        return {
            notifications: [],
            show: false,
            isLoading: false,
        };
    },
    computed: {
        counter() {
            return this.notifications.length > 9
                ? '9+'
                : this.notifications.length;
        },
    },

    mounted() {
        this.loadUnreadNotifications();

        const { endpoint, notification_channel } =
            this.$page.props.auth.user.broadcasting;

        if (endpoint && notification_channel) {
            setupEcho({
                host: endpoint,
            });

            subscribe(notification_channel, this.addNotification);
        } else {
            // Fallback: Poll for unread notifications
            eventBus.$on(
                events.updateNotifications,
                this.loadUnreadNotifications,
            );
            // Load unread notifications periodically
            eventBus.$on(events.clockUpdated, () => {
                this.throttleLoadUnreadNotifications();
            });
        }
    },

    beforeUnmount() {
        eventBus.$off([events.updateNotifications, events.clockUpdated]);
        removeEventListener('focus', this.loadUnreadNotifications);
    },

    methods: {
        addNotification(notification) {
            const notificationExists = !!this.notifications.find(
                (n) => n.id === notification.id,
            );

            if (!notificationExists) {
                this.notifications.unshift(notification);
            }
        },
        removeReadNotification(notification) {
            this.notifications = this.notifications.filter(
                (n) => n.id !== notification.id,
            );
        },
        throttleLoadUnreadNotifications: throttle(function () {
            this.loadUnreadNotifications();
        }, 60000),
        loadUnreadNotifications: function () {
            if (this.isLoading || this._isDestroyed) {
                return;
            }

            this.isLoading = true;

            $http
                .get(this.route('notifications.index'))
                .then((resp) => {
                    this.notifications = resp.data;

                    if (resp.data.length === 0) {
                        this.show = false;
                    }
                })
                .catch(() => (this.notifications = []))
                .finally(() => (this.isLoading = false));
        },
        markAllAsRead() {
            this.isLoading = true;

            $http
                .post(this.route('notifications.mark_as_read'))
                .then(() => {
                    this.notifications = [];
                })
                .finally(() => {
                    this.isLoading = false;
                    setTimeout(() => {
                        this.loadUnreadNotifications();
                    }, 15000);
                });
        },
    },
};
</script>
