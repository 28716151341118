<template>
    <Link
        v-if="hasAnyPermission(['user.read']) && info.id"
        :href="$route('users.show', info.id)"
        @click.stop
    >
        <div class="text-truncate">{{ userName }}</div>
        <small v-if="info.email" class="d-block text-muted">{{
            info.email
        }}</small>
    </Link>
    <div v-else>
        <div class="text-truncate">{{ info.name }}</div>
        <small v-if="info.email" class="d-block text-muted text-truncate">{{
            info.email
        }}</small>
    </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps(['info']);

const userName = computed(() => {
    return (
        props.info.name ||
        [props.info.firstName, props.info.lastName].filter(Boolean).join(' ')
    );
});
</script>
