import { router, useForm } from '@inertiajs/vue3';
import { removeNullValues } from '../mixins/removeNullValues';
import { onMounted } from 'vue';
import {
    transformQueryToSort,
    transformSortToQuery,
} from './transformSortToQuery';
import { useRoute } from 'ziggy-js';

export default function useQueryParams({ paramsWithDefaults, route, options }) {
    // Add page to all params
    paramsWithDefaults.page = 1;

    const form = useForm(paramsWithDefaults);
    const currentRoute = useRoute();

    const clear = () => {
        form.reset();
        loadItems();
    };

    const transformData = (data: Object) => {
        data = { ...data };

        if (options.sortKey) {
            data[options.sortKey] = transformSortToQuery(data.sort);
            delete data.sort;
        } else {
            data.sort = transformSortToQuery(data.sort);
        }

        data.page = data.page === 1 ? null : data.page;
        return removeNullValues(data);
    };

    const loadItems = () => {
        form.processing = true;

        // Can't use form directly as this would reset
        // defaults which leads to isDirty being false
        router.get(
            route,
            {
                ...transformData(form.data()),
            },
            {
                only: options?.only || [],
                preserveState: options?.preserveState || true,
                preserveScroll: options?.preserveScroll || true,
                onFinish: () => {
                    form.processing = false;
                },
            },
        );
    };

    const readParamsFromUrl = () => {
        let params = currentRoute().params;

        Object.keys(params).forEach((key) => {
            if (
                !paramsWithDefaults.hasOwnProperty(key) &&
                key !== options.sortKey
            ) {
                return;
            }

            if (key === 'sort') {
                form.sort = transformQueryToSort(
                    params.sort,
                    paramsWithDefaults.sort,
                );
            } else if (key === options.sortKey) {
                form.sort = transformQueryToSort(
                    params[key],
                    paramsWithDefaults.sort,
                );
            } else {
                form[key] = params[key];
            }
        });
    };

    onMounted(() => {
        // Set initial params
        readParamsFromUrl();
    });

    return {
        form,
        defaults: paramsWithDefaults,
        change: loadItems,
        urlParams: () => transformData(form.data()),
        clear,
    };
}
