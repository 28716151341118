<template>
    <menu-button
        :color="
            selection.selectedItemsCount.value === 0 ? 'secondary' : 'primary'
        "
        :disabled="selection.selectedItemsCount.value === 0"
        :toggle-classes="{ 'btn-icon': isMobile }"
    >
        <template #title>
            <span v-if="isDesktop">{{ __('general.action.export') }}</span>
            <icon v-else name="cil-data-transfer-down" />
        </template>
        <template #menu="{ close }">
            <div class="p-3 pb-2 text-nowrap">
                <div class="pb-1">
                    {{ __('general.export.description') }}
                </div>
                <div
                    v-if="exportError"
                    class="text-danger d-flex align-items-start"
                    style="gap: 5px"
                >
                    <div style="line-height: 16px">
                        <exclamation-mark />
                    </div>
                    <div>
                        <h3
                            class="mb-0"
                            style="font-size: 15px; font-weight: normal"
                        >
                            {{ exportError.error }}
                        </h3>
                        <p class="text-small mb-0">
                            {{ exportError.help }}
                        </p>
                    </div>
                </div>
            </div>
            <export-button
                :filters="activeQueryParams.urlParams()"
                :non-query-filters="nonQueryFilters"
                :route="routeName"
                :selection="selection.getSelectionData()"
                type="csv"
                @done="close"
                @error="onError"
            >
                <span class="d-flex align-items-center" style="gap: 5px">
                    <grid />
                    {{ __('general.file.csv') }}
                </span>
            </export-button>
        </template>
    </menu-button>
</template>
<script setup>
import { isDesktop, isMobile } from '@/Utils/UseMedia';
import ExportButton from '@/Pages/TestRecords/ExportButton.vue';
import ExclamationMark from '../../icons/ExclamationMark.vue';
import Grid from '../../icons/Grid.vue';
import MenuButton from '@/Components/MenuButton.vue';
import { ref } from 'vue';

const props = defineProps({
    selection: Object,
    activeQueryParams: Object,
    nonQueryFilters: Object,
    routeName: String,
});

const exportError = ref(null);

const onError = ({ id, error }) => {
    exportError.value = error;
};
</script>
