<template>
    <div>
        <file-list
            v-if="attachments && attachments.length"
            :attachments="attachments"
            :can-delete="hasAnyPermission(['asset.attachment.delete'])"
            :can-edit="hasAnyPermission(['asset.attachment.update'])"
            :can-read="hasAnyPermission(['asset.attachment.index'])"
            :class="{
                'mb-3':
                    hasAnyPermission(['asset.attachment.create']) &&
                    attachments.length > 0,
            }"
            :show-empty-state="!hasAnyPermission(['asset.attachment.create'])"
        />
        <div v-if="hasAnyPermission(['asset.attachment.create'])">
            <div class="mb-3">
                {{ __('general.attachment.hint') }}
            </div>
            <file-upload
                :key="componentKey"
                :invalid-feedback="form.errors.attachments"
                @uploadCompleted="onUploadCompleted"
            />
        </div>
    </div>
</template>
<script setup>
import FileList from '@/Components/FileList.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { ref } from 'vue';
import { hasAnyPermission } from '@/mixins/hasAnyPermission.js';
import { useForm } from '@inertiajs/vue3';

const componentKey = ref(0);

const props = defineProps({
    attachments: Array,
    storeRoute: String,
    form: Object,
});

const emits = defineEmits(['success']);
const form = props.form || useForm({ attachments: [] });

const onUploadCompleted = (fileIds) => {
    form.attachments = fileIds;

    if (!!props.form) {
        return;
    }

    form.post(props.storeRoute, {
        preserveScroll: true,
        onSuccess: () => {
            form.reset();
            componentKey.value++;
            emits('success');
        },
    });
};
</script>
