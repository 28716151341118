<template>
    <app-layout>
        <page-header :title="title" />
        <row>
            <column md="6">
                <jet-form-section
                    type="decent"
                    @submitted="handleSubmit($event)"
                >
                    <template #title>{{ __('locations.info.title') }}</template>
                    <template #form>
                        <jet-input
                            v-model="form.name"
                            :autofocus="true"
                            :invalidFeedback="form.errors.name"
                            :label="__('locations.name.label')"
                            :placeholder="__('locations.name.placeholder')"
                            :required="true"
                            name="name"
                        />
                        <jet-input
                            v-model="form.attributes.icaoCode"
                            :invalidFeedback="form.errors.attributes?.icaoCode"
                            :label="__('locations.icao_code.label')"
                            :placeholder="__('locations.icao_code.placeholder')"
                            :required="true"
                            name="icao_code"
                        />
                        <jet-input
                            v-model="form.attributes.iataCode"
                            :invalidFeedback="form.errors.attributes?.iataCode"
                            :label="__('locations.iata_code.label')"
                            :placeholder="__('locations.iata_code.placeholder')"
                            :required="true"
                            name="iata_code"
                        />
                        <jet-input
                            v-model="form.attributes.countryCodeAlpha2"
                            :invalidFeedback="
                                form.errors.attributes?.countryCodeAlpha2
                            "
                            :label="__('locations.country_code_alpha2.label')"
                            :placeholder="
                                __('locations.country_code_alpha2.placeholder')
                            "
                            :required="true"
                            name="country_code_2"
                        />
                        <jet-input
                            v-model="form.attributes.countryCodeAlpha3"
                            :invalidFeedback="
                                form.errors.attributes?.countryCodeAlpha3
                            "
                            :label="__('locations.country_code_alpha3.label')"
                            :placeholder="
                                __('locations.country_code_alpha3.placeholder')
                            "
                            :required="true"
                            name="country_code_3"
                        />
                        <jet-select
                            v-model="form.attributes.timezone"
                            :enable-search="true"
                            :invalidFeedback="form.errors.attributes?.timezone"
                            :label="__('locations.timezone.label')"
                            :options="timezones"
                            :placeholder="__('locations.timezone.placeholder')"
                            :required="true"
                            name="timezone"
                        />
                    </template>

                    <template #actions />
                </jet-form-section>
            </column>
            <column class="d-flex flex-column" md="6">
                <users-list />
            </column>
        </row>
        <row>
            <column>
                <location-structure
                    :location-id="location?.id"
                    :locations="childLocations"
                    @change="changeSet = $event"
                />
            </column>
        </row>
        <delete-location-modal />
        <unsaved-changes-modal />

        <jet-form-buttons
            :cancel-route="route('locations.index')"
            :disabled="!form.isDirty && changeSet.length === 0"
            :has-changes="hasChanges"
            :is-processing="form.processing"
            :is-update-form="isUpdateForm"
            @submit="handleSubmit"
        />
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import { Timezone } from '@/Utils/timezone';
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import PageHeader from '@/Components/PageHeader.vue';
import Card from '@/Components/Card.vue';
import JetButton from '@/Jetstream/Button.vue';
import Column from '@/Components/Column.vue';
import UsersList from '@/Pages/Locations/UsersList.vue';
import ConfirmedDelete from '@/Components/ConfirmedDelete.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import DeleteLocationModal from '@/Pages/Locations/DeleteLocationModal.vue';
import LocationStructure from '@/Pages/Locations/LocationStructure.vue';
import { eventBus, events } from '@/eventBus.js';
import StickyBottomSheet from '@/Components/StickyBottomSheet.vue';
import UnsavedChangesModal from '@/Pages/Locations/UnsavedChangesModal.vue';
import { router } from '@inertiajs/vue3';

export default {
    components: {
        UnsavedChangesModal,
        StickyBottomSheet,
        LocationStructure,
        DeleteLocationModal,
        DialogModal,
        ConfirmedDelete,
        UsersList,
        Column,
        JetButton,
        Card,
        PageHeader,
        MultiSelectDropdown,
        AppLayout,
        JetFormButtons,
        JetInput,
        JetFormSection,
        JetSelect,
    },

    data() {
        return {
            changeSet: [],
            removeOnBeforeListener: null,
            handleFullPageRefresh: null,
            forceLeavePage: false,
            form: this.$inertia.form({
                parentId: null,
                name: undefined,
                attributes: {
                    iataCode: undefined,
                    icaoCode: undefined,
                    countryCodeAlpha2: undefined,
                    countryCodeAlpha3: undefined,
                    timezone: undefined,
                },
            }),
        };
    },

    computed: {
        title() {
            return this.isUpdateForm
                ? this.__('locations.edit.title')
                : this.__('locations.create.title');
        },
        location() {
            return this.$page.props.location;
        },
        childLocations() {
            return this.$page.props.locationWithChildren?.children || [];
        },
        timezones() {
            return this.$page.props.timezones.data.map((timezone) => {
                let shortName = new Timezone(timezone.identifier).shortName;

                let label = `
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="text-truncate"">${timezone.name}</div>
                        <small class="text-muted">${shortName}</small>
                    </div>
                `;
                return {
                    label: label,
                    value: timezone.identifier,
                };
            });
        },
        isUpdateForm() {
            return !!this.location;
        },
        hasChanges() {
            return this.changeSet.length > 0 || this.form.isDirty;
        },
    },

    mounted() {
        this.form = this.$inertia.form({
            parentId: this.location?.parentId || null,
            name: this.location?.name,
            attributes: {
                iataCode: this.location?.iataCode,
                icaoCode: this.location?.icaoCode,
                countryCodeAlpha2: this.location?.countryCodeAlpha2,
                countryCodeAlpha3: this.location?.countryCodeAlpha3,
                timezone: this.location?.timezone?.identifier,
            },
        });

        if (this.isUpdateForm) {
            this.$inertia.visit(this.route('locations.edit', this.location), {
                preserveScroll: true,
                preserveState: true,
                only: ['locationWithChildren'],
            });
        }

        // Show confirmation dialog for unsaved changes upon leaving the page
        this.handleFullPageRefresh = (event) => {
            if (this.hasChanges) {
                event.returnValue = null;
                event.preventDefault();
            }
        };

        window.addEventListener('beforeunload', this.handleFullPageRefresh);

        this.removeOnBeforeListener = router.on('before', (event) => {
            if (
                this.hasChanges &&
                event.detail.visit.method === 'get' &&
                !this.forceLeavePage
            ) {
                this.openUnsavedChangesModal(event);
                return false;
            }

            return true;
        });
    },

    unmounted() {
        this.removeOnBeforeListener();
        window.removeEventListener('beforeunload', this.handleFullPageRefresh);
    },

    methods: {
        transformData(data) {
            data.children = this.changeSet || [];
            data.attributes.icaoCode = data.attributes.icaoCode?.toUpperCase();
            data.attributes.iataCode = data.attributes.iataCode?.toUpperCase();
            data.attributes.countryCodeAlpha2 =
                data.attributes.countryCodeAlpha2?.toUpperCase();
            data.attributes.countryCodeAlpha3 =
                data.attributes.countryCodeAlpha3?.toUpperCase();
            return data;
        },
        handleSubmit: async function () {
            if (this.isUpdateForm) {
                return this.updateLocation();
            } else {
                return this.createLocation();
            }
        },
        createLocation: async function () {
            return new Promise((resolve, reject) => {
                this.form
                    .transform(this.transformData)
                    .post(this.route('locations.store'), {
                        onSuccess: resolve,
                        onError: reject,
                    });
            });
        },
        updateLocation: async function () {
            return new Promise((resolve, reject) => {
                this.form.transform(this.transformData).put(
                    this.route('locations.update', {
                        location: this.location,
                    }),
                    {
                        preserveScroll: true,
                        only: ['location', 'locationWithChildren'],
                        onSuccess: resolve,
                        onError: reject,
                    },
                );
            });
        },
        openUnsavedChangesModal(event) {
            eventBus.$emit(events.openUnsavedChangesModal, {
                onSaveAndExitPressed: (closeModal, isProcessing) => {
                    isProcessing.value = true;

                    this.handleSubmit()
                        .then(() => {
                            router.visit(event.detail.visit.url);
                        })
                        .finally(() => {
                            isProcessing.value = false;
                            closeModal();
                        });
                },
                onExitWithoutSavingPressed: (closeModal) => {
                    this.forceLeavePage = true;
                    router.visit(event.detail.visit.url);
                },
            });
        },
    },
};
</script>
